import React, { Suspense, useState } from 'react';
import classNames from 'classnames';
import { useGetOrganizationOverview } from 'src/queries/organization/useGetOrganizationOverview';
import { useAuthStore } from 'src/store/authStore';
import { useModalStore } from 'src/store/modalStore';
import { useSubscriptionStore } from 'src/store/subscriptionStore';
import { customLoadable } from 'src/utils/application';
import { block } from './constants';
import { useMainLayoutStore } from './mainLayoutStore';
import { SideNav } from './SideNav';

const Banner = customLoadable(async () => (await import('./Banner')).Banner);
const TrialWelcomeModal = customLoadable(
  async () =>
    (await import('src/components/routes/shared/Modals/TrialWelcomeModal')).TrialWelcomeModal
);
const SubscriptionWelcomeModal = customLoadable(
  async () =>
    (await import('src/components/routes/shared/Modals/SubscriptionWelcomeModal'))
      .SubscriptionWelcomeModal
);

const DowngradeSubscriptionModal = customLoadable(
  async () =>
    (await import('src/components/routes/shared/Modals/DowngradeSubscriptionModal'))
      .DowngradeSubscriptionModal
);

const AddLicenseToDeviceModal = customLoadable(
  async () =>
    (await import('src/components/routes/shared/Modals/AddLicenseToDeviceModal'))
      .AddLicenseToDeviceModal
);

const CountrySelectModal = customLoadable(
  async () =>
    (await import('src/components/routes/shared/Modals/CountrySelectModal')).CountrySelectModal
);

const CancelSubscriptionModal = customLoadable(
  async () =>
    (await import('src/components/routes/shared/Modals/CancelSubscriptionModal'))
      .CancelSubscriptionModal
);

export type MainLayoutProps = React.PropsWithChildren<{}>;

export const MainLayout = (props: MainLayoutProps): React.ReactElement => {
  const { children } = props;
  const isAuthenticated = useAuthStore(state => state.isAuthenticated);
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const isTrialWelcomeModalVisible = useModalStore(state => state.isTrialWelcomeModalVisible);
  const isCancelSubscriptionModalVisible = useModalStore(
    state => state.isCancelSubscriptionModalVisible
  );
  const addLicenseModalDeviceId = useModalStore(state => state.addLicenseModalDeviceId);
  const isAddLicenseModalOpen = addLicenseModalDeviceId !== undefined;
  const showSideNav = useMainLayoutStore(state => state.showSideNav);
  const wrapperClassName = classNames(block, {
    [`${block}--banner-active`]: isBannerVisible,
  });
  const selectSubscriptionWelcomeModal = useSubscriptionStore(
    state => state.subscriptionWelcomeModal
  );
  const selectDowngradeSubscriptionModal = useSubscriptionStore(
    state => state.downgradeSubscriptionModal
  );
  const orgData = useGetOrganizationOverview({
    options: {
      enabled: isAuthenticated,
    },
  });
  const isCountrySelectModalOpen = !!orgData.orgId && !orgData.orgCountryCode;

  return (
    <div className={wrapperClassName}>
      {isAuthenticated && (
        <Suspense>
          <Banner onVisibilityChange={setIsBannerVisible} />
          {isAddLicenseModalOpen && <AddLicenseToDeviceModal deviceId={addLicenseModalDeviceId} />}
          {isTrialWelcomeModalVisible && <TrialWelcomeModal />}
          {selectSubscriptionWelcomeModal.show && <SubscriptionWelcomeModal />}
          {selectDowngradeSubscriptionModal.show && <DowngradeSubscriptionModal />}
          {isCountrySelectModalOpen && <CountrySelectModal />}
          {isCancelSubscriptionModalVisible && <CancelSubscriptionModal />}
        </Suspense>
      )}
      {showSideNav && <SideNav />}
      {children}
    </div>
  );
};
