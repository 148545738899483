import { MIN_PASSWORD_LENGTH } from './limits';

export const passwordsMismatch = 'Make sure your new passwords match';
export const passwordTooShort = `Passwords must be ${MIN_PASSWORD_LENGTH} characters or longer`;
export const somethingWentWrong = 'Something went wrong';
export const groupNameisRequired = 'Group name is required';
export const listDeviceError = `We're having trouble retrieving the status of your Airtame devices,
                                but we'll keep trying.`;
export const getDeviceSettingsError = 'Unable to get device settings';
export const postDeviceTokenError = 'Unable to get device token';
export const getSignageTemplatesError = 'Unable to show the Signage Overview';
export const saveAppsSettingsError = 'Unable to save apps settings';
export const getDevicePeripheralsError = 'Unable to get device peripherals';
export const splashContentTooLong = 'Must be 300 characters or fewer';
export const googleAuthenticationFailed = 'Google authentication failed. Please try again';
export const microsoftAuthenticationFailed = 'Microsoft authentication failed. Please try again';
export const unableToConnectCalendar = 'Unable to connect to your calendar. Please try again';
export const unableToLoadCalendars = 'We could not load your rooms. Please try re-authenticating';
export const noMeetingRoomsConfigured =
  'We could not retrieve any meeting rooms from your calendar provider. ' +
  'Please ensure you have at least one room configured.';
export const noMSExchangeOnlineErrorCode = 'MailboxNotEnabledForRESTAPI';
export const noMSExchangeSharedOnlineErrorCode = 'RESTAPINotEnabledForComponentSharedMailbox';
export const noMSExchangeOnline = 'Your mailbox must be on Exchange Online.';
export const MSBetaAPIRateLimitExceededErrorCode = 'ApplicationThrottled';
export const MSBetaAPIRateLimitExceeded =
  'We could not get your calendars. Please try again in a few moments';
export const unableToUpdateSubscriptionDevices = 'Unable to update devices for your subscription';
export const startTrialErrorMessage = 'Unable to start trial';
export const cancelSubscriptionErrorMessage = 'Unable to cancel subscription';

export const getBillingHistoryErrorMessage = 'Unable to get billing history';

export const unableToAddPaymentInfo = 'Unable to add payment info';
export const homescreenAppRoomError = `You do not have permission to access the selected room
calendar. To use this calendar, first request access from the calendar owner.`;
export const failedToConfirmPayment = 'Failed to confirm payment';
export const failedToObtainMassDeploymentKey = 'Failed to obtain deployment key';
export const failedThirdpatyRedirect =
  'Failed to authenticate user, please try again or contact support';

// Organization
export const updateOrganizationNameError = 'Failed to update organization name';
export const getOrganizationSettingsError = 'Unable to get organization settings';
export const putOrganizationSettingsError = 'Failed to update organization settings';
export const organizationIdCopyError = 'Error when copying Organization ID to clipboard';
export const updateBillingEmailError = 'Failed to update billing email';

// Subscription
export const postConvertPlusToEduError = 'Failed to convert plus licenses to education licenses';

export const powerpointLoadError = 'An error happened while loading the presentation.';
export const powerpointFileSizeExceeded = 'Error: The file is too big to convert';
export const invalidPasswordResetToken = 'Invalid password reset token';

// Voucher
export const incorrectVoucherMsg =
  'There seems to be a problem with the key. Please try to enter it again.';
