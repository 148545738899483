import { OnboardingTopics } from 'src/components/routes/Devices/DeviceOverview/DeviceOverviewLayout/OnboardingScreen/types';

const ns = 'devices';

export const devices = {
  page: `${ns}__page`,
  updateAvailable: `${ns}__update-available`,
  statusBadge: `${ns}__status-badge`,
  addDeviceButton: `${ns}__add-new-device`,
  addDeviceToken: `${ns}__add-device-token`,
  addDeviceRefreshTokenButton: `${ns}__add-device-refresh-token`,
  addDeviceCopyTokenButton: `${ns}__add-device-copy-token`,
  addDeviceCloseButton: `${ns}__add-device-close`,
  addDeviceModal: `${ns}__add-device-modal`,
  addDevicePasscodeAction: `${ns}__add-device-passcode-action`,
  addDeviceDeploymentKey: `${ns}__add-device-deployment-key`,
  addDeviceDeploymentKeyCopyButton: `${ns}__add-device-copy-deployment-key`,
  addNewGroupField: `${ns}__new-group`,
  emptyGroupAddDeviceAction: `${ns}__empty-group-add-device-action`,
  emptyGroupDeleteGroupAction: `${ns}__empty-group-delete-group-action`,
  omniboxSearch: `${ns}__omnibox-search`,
  omniboxSearchResults: `${ns}__omnibox-search-results`,
  editDevice: `${ns}__edit-device`,
  deviceVersionUpdateAvailableIcon: `${ns}__device-version-update-available-icon`,
  deviceViewToggle: `${ns}__device-view-toggle`,
  getDeviceVersionTooltipTriggerById: (id: number): string =>
    `${ns}__device-version-tooltip-trigger-${id}`,
  getDeviceUnlicensedTooltipTriggerById: (id: number): string =>
    `${ns}__device-unlicensed-tooltip-trigger-${id}`,
  getDeviceVersionTooltipById: (id: number): string => `${ns}__device-version-tooltip-${id}`,
  getGroupDragger: (groupName: string): string => `${ns}__${groupName}-group-dragger`,
  grid: `${ns}__grid`,
  gridCellApChecked: `${ns}__grid-cell-ap-checked`,
  gridCellApBlocked: `${ns}__grid-cell-ap-blocked`,
  selectAllDevices: `${ns}__select-all-devices`,
  screenList: `${ns}__screens-list`,
  screen: `${ns}__screen`,
  screenThumb: `${ns}__screen-thumb`,
  screenName: `${ns}__screen-name`,
  screenFullscreenOpen: `${ns}__screen-fullscreen-open-action`,
  screenFullscreenModal: `${ns}__screen-fullscreen`,
  screenFullscreenModalTitle: `${ns}__screen-fullscreen-title`,
  screenFullscreenModalClose: `${ns}__screen-fullscreen-close-action`,
  onboardingScreen: `${ns}__onboarding-view`,
  onboardingAddDeviceButton: `${ns}__onboarding-add-device`,
  onboardingCard: (name: OnboardingTopics): string => `${ns}__onboarding-card-${name}`,
  onboardingDialog: (name: OnboardingTopics): string => `${ns}__onboarding-dialog-${name}`,
  onboardingDialogImage: `${ns}__onboarding-dialog-image`,
  onboardingDialogPreviousButton: `${ns}__onboarding-dialog-previous-button`,
  onboardingDialogNextButton: `${ns}__onboarding-dialog-next-button`,
  addLicenseToDevice: `${ns}__add-license-to-device`,
  updateToCustomFirmwareButton: `${ns}__update-to-custom-firmware-button`,
  updateToCustomFirmwareModal: `${ns}__update-to-custom-firmware-modal`,
  updateToCustomFirmwareInput: `${ns}__update-to-custom-firmware-input`,
  updateToCustomFirmwareConfirm: `${ns}__update-to-custom-firmware-confirm`,
  updateToCustomFirmwareClose: `${ns}__update-to-custom-firmware-close`,
  updateToCustomFirmwareWarning: `${ns}__update-to-custom-firmware-warning`,
};
