import { SubscriptionProductName } from 'src/typings/Subscription';
import { createZustandStore } from 'src/utils/zustand';
import { SubscriptionStoreState } from './types';

export const { useStore: useSubscriptionStore, createAction } =
  createZustandStore<SubscriptionStoreState>('SubscriptionStore', {
    products: {
      plus: { devices: 0 },
      rooms: { devices: 0 },
      edu: { devices: 0 },
    },
    downgradeSubscriptionModal: {
      allocatedLicenses: 0,
      show: false,
      productName: SubscriptionProductName.plus,
    },
    subscriptionWelcomeModal: {
      show: false,
      productName: SubscriptionProductName.plus,
      numberNewLicenses: 0,
    },
  });

export function setSubscriptionProduct({
  productName,
  productTot,
}: {
  productName: SubscriptionProductName;
  productTot: number;
}): void {
  const setProductAction = createAction('setProduct', state => {
    state.products[productName] = {
      ...state.products[productName],
      devices: productTot,
    };
  });

  setProductAction();
}

export function setSubscriptionWelcomeModal(
  subscriptionWelcomeModal: Partial<SubscriptionStoreState['subscriptionWelcomeModal']>
): void {
  const setSubscriptionWelcomeModalAction = createAction('setSubscriptionWelcomeModal', state => {
    state.subscriptionWelcomeModal = {
      ...state.subscriptionWelcomeModal,
      ...subscriptionWelcomeModal,
    };
  });

  setSubscriptionWelcomeModalAction();
}

export function setDowngradeSubscriptionModal(
  downgradeSubscriptionModal: Partial<SubscriptionStoreState['downgradeSubscriptionModal']>
): void {
  const setDowngradeSubscriptionModalAction = createAction(
    'setDowngradeSubscriptionModal',
    state => {
      state.downgradeSubscriptionModal = {
        ...state.downgradeSubscriptionModal,
        ...downgradeSubscriptionModal,
      };
    }
  );

  setDowngradeSubscriptionModalAction();
}
