import React from 'react';
import classnames from 'classnames';
import { block } from './constants';

type LayoutProps = React.HTMLAttributes<HTMLDivElement>;

/**
 * Example:
 * ```tsx
 * <Layout>
 *   <LayoutHeader>
 *     <LayoutHeaderLogo />
 *     <LayoutHeaderTitle />
 *     <LayoutHeaderActions />
 *   </LayoutHeader>
 *   <LayoutSidebar>
 *       <LayoutNavbar>
 *          <LayoutNavLink to="/route">Route name</LayoutNavLink>
 *       </LayoutNavbar>
 *   </LayoutSidebar>
 *   <LayoutBody>
 *     <LayoutBodyHeader>
 *       <LayoutBodyHeaderTitle>Body Title<LayoutBodyHeaderTitle/>
 *       <LayoutBodyHeaderActions>Body Actions<LayoutBodyHeaderActions/>
 *    </LayoutBodyHeader>
 *    <LayoutBodyContent>
 *      Body content
 *    </LayoutBodyContent>
 *    <LayoutBodyFooter>Footer</LayoutBodyFooter>
 *   </LayoutBody>
 * </Layout>
 * ```
 */
export const Layout = ({ children, className, ...props }: LayoutProps): React.ReactElement => {
  const wrapperClassName = classnames(className, block);

  return (
    <div className={wrapperClassName} {...props}>
      {children}
    </div>
  );
};
