import { useEffect } from 'react';
import { useGetCalendarConnections } from 'src/queries/organization/useGetCalendarConnections';
import { useAuthStore } from 'src/store/authStore';
import { updateRecommendation } from './recommendationStore';

export function useSyncRecommendationStoreForCalendarConnection(): void {
  const isAuthenticated = useAuthStore(state => state.isAuthenticated);

  const {
    data: { activeConnections },
    isFetched,
  } = useGetCalendarConnections({
    options: {
      enabled: isAuthenticated,
    },
  });

  useEffect(() => {
    const hasCalendars = !!Object.keys(activeConnections).length;

    updateRecommendation('connectCalendar', {
      isInit: isFetched,
      canBeVisible: !hasCalendars,
    });

    // Sync 'layoutCalendarRoom.canBeVisible' with 'connectCalendar' because this
    // is visible only when a calendar is available
    updateRecommendation('layoutCalendarRoom', {
      canBeVisible: hasCalendars,
    });
  }, [activeConnections, isFetched]);
}
