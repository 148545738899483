import isNull from 'lodash/isNull';
import pluralize from 'pluralize';
import { selectedSingleGroupIdStorageKey } from 'src/constants/localStorage';
import { createZustandStore } from 'src/utils/zustand';
import { allGroupId, emptyGroupId } from '../constants';
import { DeviceView } from '../DeviceOverview/types';
import { DeviceStoreState, PersistedDeviceStoreState } from './types';
import { mergeDeviceState, parseSearchQuery } from './utils';

const initialState: DeviceStoreState = {
  isDeviceSettingsModalOpen: false,
  currentGroupId: allGroupId,
  currentGroupName: '',
  isAllGroup: false,
  showAllGroup: false,
  searchQuery: '',
  searchTerms: [],
  searchResultsLabel: '',
  isSearching: false,
  deviceView: DeviceView.List,
  selectedDeviceIds: [],
  selectedGroupId: emptyGroupId,
};

export const { useStore: useDeviceStore, createAction } = createZustandStore<
  DeviceStoreState,
  PersistedDeviceStoreState
>('deviceStore', initialState, {
  persistOptions: {
    name: selectedSingleGroupIdStorageKey,
    partialize: state => ({ selectedGroupId: state.selectedGroupId }),
    merge: mergeDeviceState,
  },
});

export function setIsDeviceSettingsModalOpen(isDeviceSettingsModalOpen: boolean): void {
  const setIsDeviceSettingsModalOpenAction = createAction('setIsDeviceSettingsModalOpen', {
    isDeviceSettingsModalOpen,
  });

  setIsDeviceSettingsModalOpenAction();
}

export function setGroups(
  currentGroupId: number,
  currentGroupName: string,
  showAllGroup: boolean
): void {
  const setGroupsAction = createAction('setGroups', {
    currentGroupId,
    currentGroupName,
    showAllGroup,
    isAllGroup: currentGroupId === allGroupId && showAllGroup,
  });

  setGroupsAction();
}

export function setSelectedGroupId(selectedGroupId: number): void {
  const setSelectedGroupIdAction = createAction('setActiveGroupId', {
    selectedGroupId,
  });

  setSelectedGroupIdAction();
}

export function setSearchQuery(searchQuery: string): void {
  const setSearchQueryAction = createAction('setSearchQuery', state => {
    const searchTerms = parseSearchQuery(searchQuery);

    state.searchQuery = searchQuery;
    state.isSearching = searchQuery.length >= 1;
    state.searchTerms = searchTerms;
  });

  setSearchQueryAction();
}

export function setSearchResults(results: number | null, resourceName?: string): void {
  const setSearchResultsAction = createAction('setSearchResults', state => {
    if (isNull(results)) {
      state.searchResultsLabel = '';
    } else {
      const label = pluralize('result', results);
      const resultsLabel = `${results} ${label} found${resourceName ? ` in ${resourceName}` : ''}`;

      state.searchResultsLabel = resultsLabel;
    }
  });

  setSearchResultsAction();
}

export function setDeviceView(deviceView: DeviceView): void {
  const setDeviceViewAction = createAction('setDeviceView', state => {
    state.deviceView = deviceView;
  });

  setDeviceViewAction();
}

export function setSelectedDeviceIds(selectedDeviceIds: number[]): void {
  const setSelectedDeviceIdsAction = createAction('setSelectedDeviceIds', {
    selectedDeviceIds,
  });

  setSelectedDeviceIdsAction();
}
