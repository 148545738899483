import { useGetUserData } from 'src/queries/user/useGetUserData';
import { UserRole } from '../typings/User';

export function useHasUserRole(): {
  isOwnerRole: boolean;
  isOwnerOrAdminRole: boolean;
  isAdminRole: boolean;
  isManagerRole: boolean;
  isUserRole: boolean;
  isModeratorRole: boolean;
} {
  const user = useGetUserData();
  const userRole = user.role;

  return {
    isOwnerRole: userRole === UserRole.owner,
    isOwnerOrAdminRole: userRole === UserRole.owner || userRole === UserRole.administrator,
    isAdminRole: userRole === UserRole.administrator,
    isManagerRole: userRole === UserRole.manager,
    isUserRole: userRole === UserRole.user,
    isModeratorRole: userRole === UserRole.moderator,
  };
}
