import React, { useMemo } from 'react';
import { RecommendationPriorityIcon } from './RecommendationPriorityIcon';
import { useRecommendationStore } from './recommendationStore';
import { RecommendationIconKey } from './types';
import { isRecommendationVisible, sortRecommendationByPriority } from './utils';

export const RecommendationSideNavIcon = (): React.ReactElement | null => {
  const state = useRecommendationStore();
  const priorityIconKey: RecommendationIconKey = useMemo(
    () =>
      Object.values(state).sort(sortRecommendationByPriority).find(isRecommendationVisible)
        ?.priority ?? 'none',
    [state]
  );

  // Check that all data is initialized to avoid flickering issues
  const isRecommendationsInit = useMemo(() => Object.values(state).every(r => r.isInit), [state]);

  if (!isRecommendationsInit) {
    return null;
  }

  return <RecommendationPriorityIcon priority={priorityIconKey} />;
};
