import { DeviceSetupStoreState } from 'src/components/routes/Devices/DeviceSetup/deviceSetupStore/types';

const ns = 'device-setup';

export const deviceSetup = {
  page: `${ns}__page`,
  headerCancelSetupAction: `${ns}__header-cancel-setup-action`,
  cancelSetupModalBackAction: `${ns}__cancel-setup-modal-back-action`,
  cancelSetupModalConfirmAction: `${ns}__cancel-setup-modal-confirm-action`,
  passcodeForm: `${ns}__passcode-form`,
  passcodeFormContinueAction: `${ns}__passcode-form-continue-action`,
  passcodeFormForm: `${ns}__passcode-retry-form`,
  passcodeFormFormTryAgainAction: `${ns}__passcode-retry-form-try-again-action`,
  passcodeFormFormBackAction: `${ns}__passcode-retry-form-back-action`,
  padsscodeBluetoothFormContinueAction: `${ns}__passcode-bluetooth-form-continue-action`,
  bluetoothStartScan: `${ns}__bluetooth-start-scan`,
  networkForm: `${ns}__network-form`,
  networkFormContinueAction: `${ns}__network-form-continue-action`,
  networkFormBackAction: `${ns}__network-form-back-action`,
  settingsForm: `${ns}__settings-form`,
  settingsFormContinueAction: `${ns}__settings-form-continue-action`,
  settingsFormBackAction: `${ns}__settings-form-back-action`,
  successMessage: `${ns}__success-message`,
  successMessageNoLicense: `${ns}__success-message-no-license`,
  successMessageHasLicense: `${ns}__success-message-has-license`,
  successMessageSetupAnotherDeviceAction: `${ns}__success-message-setup-another-device-action`,
  successMessageCustomizeSettingsAction: `${ns}__success-message-done-action`,
  licenseAssignment: `${ns}__license-assignment`,
  noLicenseAccordion: `${ns}__no-license-accordion`,
  noLicenseAccordionToggle: `${ns}__no-license-accordion-toggle`,
  noLicenseAction: `${ns}__no-license-action`,
  startTrialAccordion: `${ns}__start-trial-accordion`,
  startTrialAccordionToggle: `${ns}__start-trial-accordion-toggle`,
  startTrialAction: `${ns}__start-trial-action`,
  trialStarted: `${ns}__trial-started`,
  trialStartedAction: `${ns}__trial-started-action`,
  redeemVoucher: `${ns}__redeem-voucher`,
  redeemVoucherAccordion: `${ns}__redeem-voucher-accordion`,
  redeemVoucherAccordionToggle: `${ns}__redeem-voucher-accordion-toggle`,
  redeemVoucherAccordionContent: `${ns}__redeem-voucher-accordion-content`,
  checkVoucherAction: `${ns}__check-voucher-action`,
  redeemVoucherCard: `${ns}__redeem-voucher-card`,
  redeemVoucherCardWarning: `${ns}__redeem-voucher-card-warning`,
  redeemVoucherAction: `${ns}__redeem-voucher-action`,
  redeemVoucherSuccessCard: `${ns}__redeem-voucher-success-card`,
  redeemVoucherSuccessContinueAction: `${ns}__redeem-voucher-success-continue-action`,
  getField: (fieldName: keyof DeviceSetupStoreState['fields']): string =>
    `${ns}__field-${fieldName}`,
  getFieldError: (fieldName: keyof DeviceSetupStoreState['fields']): string =>
    `${ns}__field-${fieldName}-error`,
  getFieldRemoveUploadAction: (fieldName: keyof DeviceSetupStoreState['fields']): string =>
    `${ns}__field-${fieldName}-remove-upload-action`,
};
