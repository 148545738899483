const ns = 'global-modals';

export const globalModals = {
  subscriptionWelcome: `${ns}__subscription-welcome-modal`,
  subscriptionWelcomeText: `${ns}__subscription-welcome-modal-text`,
  trialWelcome: `${ns}__trial-welcome-modal`,
  countrySelect: `${ns}__country-select-modal`,
  countrySelectText: `${ns}__country-select-modal-text`,
  countrySelectSelect: `${ns}__country-select-modal-select`,
  updateBilling: `${ns}__update-billing-information`,
  cancelSubscription: `${ns}__cancel-subscription`,
  cancelSubscriptionConfirmButton: `${ns}__cancel-subscription-confirm-button`,
  cancelSubscriptionSuccess: `${ns}__cancel-subscription-success`,
  cancelSubscriptionSuccessCloseButton: `${ns}__cancel-subscription-success-close-button`,
  editBillingEmailDialog: `${ns}__edit-billing-email-dialog`,
  editBillingEmailInput: `${ns}__edit-billing-email-input`,
  editBillingEmailConfirmButton: `${ns}__edit-billing-email-confirm-button`,
  editBillingAddressDialog: `${ns}__edit-billing-address-dialog`,
  editBillingAddressConfirmButton: `${ns}__edit-billing-address-confirm-button`,
  downgradeSubscription: `${ns}__downgrade-subscription-modal`,
  downgradeSubscriptionContinueButton: `${ns}__downgrade-subscription-modal-continue-button`,
};
