import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSyncRecommendationStoreForCalendarConnection } from 'src/components/routes/Recommendation';
import { useOpenFeatureBootstrap } from 'src/components/routes/root/useOpenFeatureBootstrap';
import { usePageVisibility } from 'src/components/routes/root/usePageVisibility';
import { useGoogleIdentityInitializer } from 'src/components/routes/shared/GoogleAuth/useGoogleIdentityInitializer';
import { AuthRoute, NoAuthRoute, PublicRoute } from 'src/components/routes/shared/Routes';
import { FullscreenLoader } from 'src/components/ui/FullscreenLoader';
import * as routes from 'src/constants/routes';
import { useZendesk } from 'src/hooks/useZendesk';
import { useAuthStore } from 'src/store/authStore';
import { UserPermissions } from 'src/typings/User';
import { customLoadable } from 'src/utils/application';
import { Redirect } from '../shared/Routes/Redirect';
import { MainLayout } from './MainLayout';
import { useClearAllOnLogout } from './useClearAllOnLogout';

const fallbackOpts = { fallback: <FullscreenLoader /> };

const Devices = customLoadable(
  async () => (await import('src/components/routes/Devices')).Devices,
  fallbackOpts
);
const BlankRedirect = customLoadable(
  async () => (await import('src/components/routes/BlankRedirect')).BlankRedirect,
  fallbackOpts
);
const OneDriveRedirect = customLoadable(
  async () => (await import('src/components/routes/OneDriveRedirect')).OneDriveRedirect
);
const AuthRedirect = customLoadable(
  async () => (await import('src/components/routes/AuthRedirect')).AuthRedirect,
  fallbackOpts
);
const Signup = customLoadable(
  async () => (await import('src/components/routes/Signup')).Signup,
  fallbackOpts
);
const Login = customLoadable(
  async () => (await import('src/components/routes/Login')).Login,
  fallbackOpts
);
const Checkout = customLoadable(
  async () => (await import('src/components/routes/Checkout')).Checkout,
  fallbackOpts
);
const Profile = customLoadable(
  async () => (await import('src/components/routes/Profile')).Profile,
  fallbackOpts
);
const ResetPassword = customLoadable(
  async () => (await import('src/components/routes/ResetPassword')).ResetPassword,
  fallbackOpts
);
const PrivacyPolicy = customLoadable(
  async () => (await import('src/components/routes/PrivacyPolicy')).PrivacyPolicy,
  fallbackOpts
);
const Signage = customLoadable(
  async () => (await import('src/components/routes/Signage')).Signage,
  fallbackOpts
);
const DeviceSetup = customLoadable(
  async () => (await import('src/components/routes/Devices/DeviceSetup')).DeviceSetup,
  fallbackOpts
);
const NotFound = customLoadable(() => import('src/components/routes/NotFound'), fallbackOpts);
const OrganizationSettings = customLoadable(
  async () => (await import('src/components/routes/OrganizationSettings')).OrganizationSettings,
  fallbackOpts
);
const VoucherOverview = customLoadable(
  async () => (await import('src/components/routes/VoucherOverview')).VoucherOverview,
  fallbackOpts
);
const Recommendation = customLoadable(
  async () => (await import('src/components/routes/Recommendation')).Recommendation,
  fallbackOpts
);

export const Routes = (): React.ReactElement => {
  const isAuthenticated = useAuthStore(state => state.isAuthenticated);
  const layoutKey = isAuthenticated ? 1 : 0;

  useSyncRecommendationStoreForCalendarConnection();
  useOpenFeatureBootstrap();
  useZendesk();
  useGoogleIdentityInitializer();
  usePageVisibility();
  useClearAllOnLogout();

  return (
    <MainLayout
      // Trick used to force the MainLayout to reset its local state
      // whenever the user logs in/out
      key={layoutKey}
    >
      <Switch>
        {/* Auth routes */}
        <AuthRoute path={routes.devices} component={Devices} showNav exact />
        <AuthRoute path={routes.deviceSetup} component={DeviceSetup} exact />
        <AuthRoute path={routes.profile} component={Profile} showNav exact />
        <AuthRoute path={routes.checkout} component={Checkout} showNav exact />
        <AuthRoute
          path={routes.signage}
          component={Signage}
          showNav
          userPermissions={[UserPermissions.CanChangeScreenSettings]}
          exact
        />
        <AuthRoute path={routes.organizationSettings} component={OrganizationSettings} showNav />
        <AuthRoute path={routes.voucherOverview} component={VoucherOverview} showNav />
        <AuthRoute path={routes.recommendation} component={Recommendation} showNav />
        <AuthRoute path={routes.authRedirect} component={AuthRedirect} exact />
        <AuthRoute path={routes.oneDriveLoader} component={OneDriveRedirect} exact />

        {/* No Auth routes */}
        <NoAuthRoute path={routes.login} component={Login} exact />
        <NoAuthRoute path={routes.resetPassword} component={ResetPassword} exact />
        <NoAuthRoute path={routes.signup} component={Signup} />

        {/* Public routes */}
        <PublicRoute path={routes.privacy} component={PrivacyPolicy} exact />

        {/* Redirect routes */}
        <Redirect from={routes.root} to={routes.devices} exact />
        <Redirect from={routes.deprecatedNotifications} to={routes.alertNotifications} exact />
        <Redirect from={routes.deprecatedUsers} to={routes.userManagement} exact />
        <Redirect from={routes.deprecatedAccount} to={routes.subscription} exact />

        {/* NOTE: The application is using the Microsoft MSAL library for SSO authentication
        with Microsoft, the library opens a pop-up in the context of Microsoft and expects
        the user to include the credentials. After submitting those credentials the page gets
        redirected back to the Cloud application within the pop-up window. At this point, the MSAL
        library gets instantiated again in the pop-up and by reading specific information included
        as url parameters is able to decide to close the pop-up and notify the host Cloud page
        that the authentication process was successful. That's why the route blank redirect is here
        it provides a page in the application without manipulating any information from the URL
        parameter and allowing the MSAL library do its own processing. */}
        <Route path={routes.blankRedirect} component={BlankRedirect} exact />
        <Route component={NotFound} />
      </Switch>
    </MainLayout>
  );
};
