import { manageDeviceRecommendations } from 'src/components/routes/Recommendation';
import { deviceApi } from 'src/constants/api';
import * as http from 'src/constants/http';
import {
  canDevicePlatformSupportScreenshot,
  getActiveSubscriptionProduct,
  getDevicePlatform,
  getDeviceStatus,
  getLicenseProductsByPlatformType,
  getNetworkData,
  getNetworkStateMap,
  getProductsFromSubscriptionAndTrial,
  isDeviceOnline,
  isVirtualAirtame,
} from 'src/services/device/utils';
import { DevicePlatform } from 'src/typings/Device';
import {
  DeviceGroupOverview,
  DeviceOverview,
  DeviceOverviewById,
} from 'src/typings/DeviceOverview';
import { SubscriptionLicense, SubscriptionProductName } from 'src/typings/Subscription';
import { fetchJson } from 'src/utils/fetch';
import { DeviceOverviewApiModel } from './apiModels';
import { notApplicableText } from './constant';

export async function getDeviceOverviewService(): Promise<DeviceGroupOverview> {
  const response: DeviceOverviewApiModel[] = await fetchJson(deviceApi.devices(), {
    method: http.GET,
  });

  return transformDevicesForFe(response);
}

export function transformDevicesForFe(devices: DeviceOverviewApiModel[]): DeviceGroupOverview {
  const getVirtualAirtameValue = <T>(platform: DevicePlatform | undefined, value: T): T =>
    platform && isVirtualAirtame(platform) ? (notApplicableText as T) : value;

  const devicesById: DeviceOverviewById = {};
  const { setDeviceRecommendations, syncRecommendationStore } = manageDeviceRecommendations();

  const newGroups = devices.map(group => {
    const devices = group.devices.map(device => {
      const { state: deviceState, lastSeen, isOnline, platform } = device;
      const networkState = getNetworkStateMap(device.networkState.interfaces);
      const computeIsOnline = isDeviceOnline(lastSeen, isOnline);
      const { ip, network, rssi } = getNetworkData(networkState, computeIsOnline);
      const status = getDeviceStatus(deviceState, computeIsOnline);
      const deviceNetwork = getVirtualAirtameValue(platform, network);
      const deviceIp = getVirtualAirtameValue(platform, ip);
      const deviceApText = getVirtualAirtameValue(platform, '');
      const deviceRssi = getVirtualAirtameValue(platform, rssi);
      const devicePlatform = getDevicePlatform(platform);
      const allProducts = getLicenseProductsByPlatformType(
        devicePlatform,
        getProductsFromSubscriptionAndTrial(device.subscriptionProducts, device.trialProducts)
      );
      const activeSubscriptionLicense = markPaidDevice(allProducts);
      const deviceData: DeviceOverview = {
        ...device,
        // TODO (AIR-1868): Remove these random values when (AIR-1896) is implemented
        apPsk: Math.random() > 0.5 ? `ASDASD234` : '',
        managePassword: Math.random() > 0.5,
        layoutCalendarRoom: Math.random() > 0.5 ? `ASDASD234` : '',
        networkState,
        status,
        apText: deviceApText,
        platform: devicePlatform,
        network: deviceNetwork,
        ip: deviceIp,
        rssi: deviceRssi,
        isOnline: computeIsOnline,
        screenshotEnabled:
          canDevicePlatformSupportScreenshot(devicePlatform) && device.screenshotEnabled,
        groupName: group.groupName,
        groupId: group.groupId,
        activeSubscriptionLicense,
        activeSubscriptionProduct: getActiveSubscriptionProduct(allProducts),
        hasLicense: !!activeSubscriptionLicense,
        hasProductLicense: !!device.subscriptionProducts.length,
      };
      devicesById[device.id] = deviceData;
      setDeviceRecommendations(deviceData);

      return device.id;
    });

    return { ...group, devices, isEmpty: devices.length === 0 };
  });

  syncRecommendationStore();

  return { groups: newGroups, devicesById };
}

function markPaidDevice(products: SubscriptionProductName[]): SubscriptionLicense | undefined {
  if (
    products.includes(SubscriptionProductName.plus) ||
    products.includes(SubscriptionProductName.edu)
  )
    return SubscriptionLicense.core;
  if (products.includes(SubscriptionProductName.rooms)) return SubscriptionLicense.hybrid;

  return undefined;
}
