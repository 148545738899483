import { createZustandStore } from 'src/utils/zustand';
import { RecommendationKey, RecommendationPriority, RecommendationValue } from '../types';
import { RecommendationStoreState } from './types';
import { mergeRecommendationState } from './utils';

const recommendationDefaults: RecommendationValue = {
  key: 'apPsk',
  isInit: false,
  dismissed: false,
  canBeVisible: true,
  priority: RecommendationPriority.low,
};

const initialState: RecommendationStoreState = {
  apPsk: {
    ...recommendationDefaults,
    key: 'apPsk',
    deviceIds: [],
    priority: RecommendationPriority.high,
  },
  managePassword: {
    ...recommendationDefaults,
    key: 'managePassword',
    deviceIds: [],
    priority: RecommendationPriority.high,
  },
  layoutCalendarRoom: {
    ...recommendationDefaults,
    key: 'layoutCalendarRoom',
    deviceIds: [],
    priority: RecommendationPriority.medium,
  },
  connectCalendar: {
    ...recommendationDefaults,
    key: 'connectCalendar',
    priority: RecommendationPriority.medium,
  },
};

export const { useStore: useRecommendationStore, createAction } =
  createZustandStore<RecommendationStoreState>('RecommendationStore', initialState, {
    persistOptions: {
      name: 'recommendations',
      // Sync dismissed state from localstorage
      merge: mergeRecommendationState,
    },
  });

export function updateRecommendation(
  recommendationKey: RecommendationKey,
  recommendationValue: Partial<RecommendationValue>
): void {
  const updateRecommendationAction = createAction('updateRecommendation', state => {
    state[recommendationKey] = {
      ...state[recommendationKey],
      ...recommendationValue,
    };
  });

  updateRecommendationAction();
}
