import { useHasUserRole } from 'src/hooks/useHasUserRole';
import { useGetCalendarConnections } from 'src/queries/organization/useGetCalendarConnections';
import { useAuthStore } from 'src/store/authStore';
import { useNotificationStore } from 'src/store/notificationStore';

/**
 * Returns true if the call to action notification for the Calendar Connections should be visible
 * throughout the Application.
 * It's true if the user is an admin or owner, and doesn't have any calendar connections set up, and
 * hasn't dismissed the notification yet.
 *
 */
export function useShouldDisplayCalendarConnectionNotification(): boolean {
  const isAuthenticated = useAuthStore(state => state.isAuthenticated);
  const { isOwnerOrAdminRole } = useHasUserRole();

  const { data } = useGetCalendarConnections({
    options: {
      enabled: isAuthenticated,
    },
  });
  const { activeConnections, invalidConnections } = data;
  const hasCalendarConnections =
    !!Object.keys(activeConnections).length || !!Object.keys(invalidConnections).length;
  const isAddCalendarConnectionNotificationVisible = useNotificationStore(
    state => state.addCalendarConnection
  );

  return (
    isOwnerOrAdminRole && !hasCalendarConnections && isAddCalendarConnectionNotificationVisible
  );
}
