import React from 'react';
import { IconCheckmarkCircle, IconInformationSolid, IconWarningSign } from '@airtame/ui';
import { block } from './constants';
import { RecommendationIconKey, RecommendationPriority } from './types';

export const iconMap: Record<
  RecommendationIconKey,
  (props: React.SVGProps<SVGSVGElement>) => JSX.Element
> = {
  high: IconWarningSign, // TODO (AIR-1868): change with correct icon
  medium: IconWarningSign,
  low: IconInformationSolid,
  none: IconCheckmarkCircle,
};

export type RecommendationPriorityIconProps = {
  priority: RecommendationPriority | 'none';
};

export const RecommendationPriorityIcon = (
  props: RecommendationPriorityIconProps
): React.ReactElement => {
  const { priority } = props;

  const Icon = iconMap[priority];

  return <Icon className={`${block}__icon ${block}__icon--${priority}`} />;
};
