import { SubscriptionProductName } from 'src/typings/Subscription';

export const root = '/';

// Logged in routes
export const devices = '/devices';
export const deviceSetup = '/devices/setup';
export const profile = '/profile';
export const checkout = '/checkout';
export const checkoutWithProduct = (productName: SubscriptionProductName): string =>
  `/checkout?product=${productName}`;
export const signage = '/signage';
export const organizationSettings = '/organization-settings';
export const alertNotifications = `${organizationSettings}/alert-notifications`;
export const exportDeviceData = `${organizationSettings}/export-device-data`;
export const userManagement = `${organizationSettings}/user-management`;
export const subscription = `${organizationSettings}/subscription`;
export const billing = `${organizationSettings}/billing`;
export const organization = `${organizationSettings}/organization`;
export const calendarConnection = `${organizationSettings}/calendar-connection`;
export const voucherOverview = '/voucher-overview';
export const recommendation = '/recommendations';

// oAuth Redirects
export const authRedirect = '/auth-redirect';
export const blankRedirect = '/blank-redirect';
export const oAuth = '/auth/oauth2';
export const oAuthMicrosoft = '/auth/oauth2/microsoft';
export const oAuthGoogle = '/auth/oauth2/google';
export const oneDriveLoader = '/onedrive-redirect';

// Logged out routes
export const login = '/login';
export const resetPassword = '/reset-password';
export const signup = '/signup';
export const signupWelcomeStep = `${signup}/welcome`;
export const signupUserStep = `${signup}/user`;
export const signupOrganizationStep = `${signup}/organization`;
export const signupAcceptInvitation = `${signup}/accept`;

// Public routes
export const privacy = '/privacy';

// Deprecated routes
export const deprecatedNotifications = '/notifications';
export const deprecatedUsers = '/users';
export const deprecatedAccount = '/account';
export const deprecatedLicenses = `${organizationSettings}/licenses`;
