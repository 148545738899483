import { OmniboxSearchTerm } from 'src/components/ui/Omnibox';
import { selectedSingleGroupIdStorageKey } from 'src/constants/localStorage';
import { emptyGroupId } from '../constants';
import { DeviceStoreState, PersistedDeviceStoreState } from './types';

export function parseSearchQuery(searchQuery: string): OmniboxSearchTerm[] {
  if (!searchQuery) {
    return [];
  }
  // querySearch is in form of "value + key: value + key:value+value"
  // trim it and break it by the "+"
  const canonicalize = (s: string) => s.trim().toLowerCase();
  const splitQuery = searchQuery.split(`+`).map(canonicalize);

  return splitQuery.map(query => {
    // try to split the "key:value" or just "value"
    const searchTerm = query.split(':').map(canonicalize);
    const [key, value] = searchTerm;

    return {
      key: value ? key : undefined,
      value: value ? value : key,
    };
  });
}

export function mergeDeviceState(
  persistedState: PersistedDeviceStoreState,
  currentState: DeviceStoreState
): DeviceStoreState {
  // if the persisted state is empty, we try to read it directly from localstorage
  // to migrate the legacy value stored directly in `useLocalStorageState`
  // if we don't have anything stored, we fall back to `emptyGroupId`
  if (!persistedState) {
    const key = selectedSingleGroupIdStorageKey;
    const stateToMigrate = localStorage.getItem(key) ?? emptyGroupId;
    return {
      ...currentState,
      selectedGroupId: Number(stateToMigrate),
    };
  }

  return { ...currentState, ...persistedState };
}
