import { DeviceOverview } from 'src/typings/DeviceOverview';
import { ValueOf } from 'src/typings/global';

export const DeviceView = {
  List: 'list',
  Grid: 'grid',
} as const;

export type DeviceView = ValueOf<typeof DeviceView>;

export type SearchableDeviceOverviewField =
  | Lowercase<
      Extract<
        keyof DeviceOverview,
        | 'state'
        | 'status'
        | 'network'
        | 'ip'
        | 'rssi'
        | 'version'
        | 'platform'
        | 'apPsk'
        | 'managePassword'
        | 'layoutCalendarRoom'
      >
    >
  | 'group'
  | 'name'
  | 'ap'
  | 'signal'
  | 'license'
  | 'device';

export const searchableDeviceOverviewFields = [
  'platform',
  'state',
  'version',
  'status',
  'network',
  'ip',
  'rssi',
  'appsk',
  'managepassword',
  'layoutcalendarroom',
  'group',
  'name',
  'ap',
  'signal',
  'license',
  'device',
] satisfies SearchableDeviceOverviewField[];
